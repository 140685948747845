<template>
  <div v-if="!loadingStore">
      <div
          class="cover-store-profile" :style="{
      'background-image': 'url(' + allStoreInfo.cover + ')',
           'background-size' : 'cover',
                  'background-position': 'center',
                  'background-repeat': 'no-repeat'
    }"></div>
      <main class="main-profile">
        <b-container>
          <header-profile
              :profileImage = 'allStoreInfo'
              :storeHeader= allStoreInfo
          />
          <transition name="router-anim" :enter-active-class="`animated fadeInUp`" mode="out-in"
                      :leave-active-class="`animated fadeOut`">
            <router-view></router-view>
          </transition>
        </b-container>
      </main>
  </div>
  <div v-else class="d-flex align-items-center justify-content-center mt-5">
    <b-spinner large type="grow" variant="primary"></b-spinner>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
import headerProfile from '@/modules/servicePages/marketplace/components/profile/headerProfile'
export default {
  components: { headerProfile },
  data () {
    return {
      coverImage: require('@/assets/images/ibbil/ibbil-cover.jpg'),
      profileImage: require('@/assets/images/ibbil/ibbil-cover.jpg'),
      allStoreInfo: {},
      loadingStore: true
    }
  },
  methods: {
    getStoreInfo () {
      this.loadingStore = true
      marketPlace.getStoreInfo(this.$route.params.slogan).then(res => {
        this.allStoreInfo = res.data
        this.$store.commit('cart/changeSelectedStore', res.data)
      }).finally(() => {
        this.loadingStore = false
      })
    }
  },
  created () {
    this.getStoreInfo()
  },
  mounted () {
    core.index()
  }
}
</script>
<style lang="scss">
@media (max-width: 860px) {
  .main-profile {
    transform: translateY(-80px) !important;
  }
  .main-profile {
    margin-bottom: -90px !important;
  }
  .cover-store-profile {
    height: 172px !important;
  }
}
.main-profile {
  transform: translateY(-197px)
}
.main-profile {
  margin-bottom: -180px;
}
.cover-store-profile {
  width: 100%;
  height: 325px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.cover-store-profile::after {
  background: transparent linear-gradient(180deg, #00000000 0%, #000000e3 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
}
.image-profile{
  width: 250px;
  height: 250px;
  background-size: cover;
  box-shadow: 0px 3px 18px #00000029;
}
.star-icons img {
  width: 18px;
}
.tab_list {
  background: transparent;
  padding: 13px 25px;
  border-radius: 0px 0px 15px 15px;
  color: #646464;
  box-shadow: 0px 3px 18px #00000029;
  margin-bottom: 0;
}
.tab_list.router-link-active {
  background: #fff;
  position: relative;
  overflow: hidden;
}
.tab_list.router-link-active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: var(--iq-primary);
  content: '';
}
.icons i {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-left: 10px;
  color: var(--iq-primary);
  font-size: 25px;
  font-weight: bold;
  box-shadow: 0px 3px 18px #00000029;
}
.active-categories {
  box-shadow: 0px 3px 18px #5CBA8C6A;
  border: 3px solid #52C98F;
}
.padding-all-categories {
  padding: 0px 64px !important;
}
.shadow-categories {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 18px #00000029;
}
.arrow-category span{
  z-index: 100;
}
.arrow-category span .icon-category{
  color: #FFFFFF !important;
  font-size: 24px;
  width: 42px;
  height: 42px;
  background-color: #3F6854 ;
  border-radius: 50%;
  text-align: center;
}
.left-20{
  left: -20px !important;
}
.category-image-background{
  background-size: cover;
  background-position: center;
  width: 111px;
  height: 111px;
  background-color: var(--iq-white);
}
</style>
