<template>
  <div class="profile-content d-flex align-items-end justify-content-between flex-column flex-lg-row">
    <div class="image-and-info d-flex align-items-lg-end gap_2 flex-lg-row flex-column align-items-center justify-content-center">
      <div class="image-profile iq-border-radius-20" :style="{ 'background-image': 'url(' + storeHeader.logo + ')' ,
       'background-size': 'cover', 'background-color': '#fff', 'background-position' : 'center', 'background-repeat' : 'no-repeat'
      }"></div>
      <div class="details">
        <h1 class="mb-2 font-weight-bold header-image">{{storeHeader.name}}</h1>
        <div class="icons mobile-icons" >
          <i @click="shareLink" class="las la-link"></i>
          <span v-for="(i, key) in storeHeader.social_media_links" :key="key">
            <a :href="i.link" target="_blanck"> <i class="lab la-facebook-f" v-if="i.selectedSocial === 'Facebook'"></i></a>
            <a :href="i.link" target="_blanck">  <i class="lab la-globe" v-if="i.selectedSocial === 'Website'"></i></a>
            <a :href="i.link" target="_blanck"> <i class="lab la-instagram" v-if="i.selectedSocial === 'Instagram'"></i></a>
          </span>
        </div>
        <div class="d-flex justify-content-between w-100 align-items-center mb-2 flex-lg-row flex-column align-items-center justify-content-center">
          <div class="d-flex gap_3">
            <div class="d-flex gap_1"><img :src="require('@/assets/images/ibbil/truck.svg')" class="width-2" />  <span class="ms-1 profile-icon-header">{{ $t('marketplace.driveTo') }} 4 {{ $t('main.cities') }}</span> </div>
<!--            <div class="d-flex gap_1"><img :src="require('@/assets/images/ibbil/productProfile.svg')" class="width-2" />  <span class="ms-1 profile-icon-header">{{totalProducts}} {{ $t('main.product') }}</span> </div>-->
          </div>
          <div class="star-icons d-flex justify-content-center align-items-center d-lg-block d-none mx-5">
            <span class="rate-number font-size-18 p-0 m-0">(15)</span>
            <img
                class="inline-flex mr-1"
                :src="require('@/assets/images/ibbil/star.svg')"
            />
            <img
                class="inline-flex mr-1"
                :src="require('@/assets/images/ibbil/star.svg')"
            />
            <img
                class="inline-flex mr-1"
                :src="require('@/assets/images/ibbil/star.svg')"
            />
            <img
                class="inline-flex mr-1"
                :src="require('@/assets/images/ibbil/star.svg')"
            />
            <img
                class="inline-flex mr-1"
                :src="require('@/assets/images/ibbil/star.svg')"
            />
          </div>
        </div>
        <ul class="tabs d-flex justify-content-start gap_2 mb-0 profile-tab">
          <router-link :to="{name: 'storeProfile'}" class="tab_list" tag="li" exact>{{ $t('main.products') }}</router-link>
          <router-link :to="{name: 'aboutStore'}" class="tab_list" tag="li" exact>{{ $t('marketplace.aboutStore') }}</router-link>
          <router-link :to="{name: 'privacyPolicyStore'}" class="tab_list" tag="li" exact>{{ $t('marketplace.TermsAndConditions') }}</router-link>
          <!--                v-if="selectedTab === 'our-products' ? 'active' : ''"-->
        </ul>
      </div>
    </div>
    <div class="icons d-lg-block d-none cursor-pointer" >
      <i @click="shareLink" class="las la-link"></i>
      <span v-for="(i, key) in storeHeader.social_media_links" :key="key">
        <a :href="i.link" target="_blanck"> <i class="lab la-facebook-f" v-if="i.selectedSocial === 'Facebook'"></i></a>
        <a :href="i.link" target="_blanck">  <i class="lab la-globe" v-if="i.selectedSocial === 'Website'"></i></a>
        <a :href="i.link" target="_blanck"> <i class="lab la-instagram" v-if="i.selectedSocial === 'Instagram'"></i></a>
      </span>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
// import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
export default {
  props: ['profileImage', 'storeHeader'],
  data () {
    return {
      totalProducts: null
    }
  },
  methods: {
    // getAllProducts () {
    //   // this.loadingProducts = true
    //   marketPlace.getAllProducts(this.$route.params.slogan, this.selectSort).then(res => {
    //     this.totalProducts = res.data.meta.totalItems
    //   }).finally(() => {
    //     // this.loadingProducts = false
    //   })
    // },
    shareLink () {
      console.log(window.location)
      const storePath = window.location.origin + window.location.pathname.replace('/privacy-store', '').replace('/about-store', '')
      navigator.clipboard.writeText(storePath)
      core.showSnackbar('success', 'تم نسخ الرابط بنجاح')
    }
  },
  created () {
    // this.getAllProducts()
  }
}
</script>
<style>
.profile-tab{
  cursor: pointer;
}
.header-image {
  color: var(--iq-white) !important;
  text-align: initial !important;
}
.mobile-icons{
  display: none;
}
.star-icons .rate-number{
  color: var(--iq-white) !important;
}
.profile-icon-header{
  color: var(--iq-white) !important;
  font-size: 18px;
}
@media (max-width: 991.98px) {
  .image-and-info {
   width: 100%;
  }
  .image-profile{
    width: 120px !important;
    height: 120px !important;
  }
.header-image {
  color: var(--iq-primary) !important;
  font-size: 24px !important;
  text-align: center !important;
}
.icons{
display: block !important;
  text-align: center;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.star-icons{
  display: block !important;
  text-align: center !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
  .star-icons .rate-number{
    color: var(--iq-secondary-dark) !important;
  }
  .profile-icon-header{
   color: var(--iq-primary) !important;
   font-size: 20px;
 }
  .profile-tab{
    width: 100% !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    gap: 0 !important;
  }
  .profile-tab .tab_list{
    border-radius: 0 !important;
    padding: 20px 22px;
  }
}
/*@media (min-width: 991.98px) and (max-width: 1199.98px){*/
/*.header-image{*/
/*font-size: 24px !important;*/
/*  margin-left: 0 !important;*/
/*  padding-right: 0 !important;*/
/*}*/
/*}*/
</style>
